import React, { useEffect, useState, useCallback } from 'react';
import { 
    Snackbar,
    Card,
    Collapse, 
    Paper, 
    Typography,
    Grid, 
    Link, 
    Button,
    Slide, 
    Switch, 
    FormControlLabel,
    IconButton,
    Theme,
    createStyles,
    makeStyles,
    useTheme,
    CardContent,
} from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import Environment from '../Environments';
import { useHistory } from 'react-router-dom';
import CookiesConsentState  from '../Types/cookies'
import { useCookies } from 'react-cookie';

interface CookieCategoryProps {
  title: string;
  description: string;
  checked: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
    snackbarRoot: {
      margin: "0 auto",
      "@media (min-width: 600px)": {
        width: "90%",
      },
      "@media (min-width: 960px)": {
        width: "50%",
      },
    },
    container: {
      padding: theme.spacing(3),
      maxWidth: 1400,
      backgroundColor: theme.palette.secondary.main,
      borderRadius: 10,
    },
    buttonContainer: {
      display: 'flex',
      width: "100%",
      minHeight: "36px",
    },
    stickyHeader: {
      padding: theme.spacing(1),
    },
    stickyFooter: {
      padding: theme.spacing(3),
    },
    scrollableContent: {
        overflowY: 'auto',
        maxHeight: 400,
        width: "100%",
        padding: theme.spacing(2, 0),
        '&::-webkit-scrollbar': {
          width: '12px',
          backgroundColor: '#F5F5F5',
        },
        '&::-webkit-scrollbar-track': {
          borderRadius: '10px',
          backgroundColor: '#F5F5F5',
          boxShadow: 'inset 0 0 6px rgba(0,0,0,0.3)',
        },
        '&::-webkit-scrollbar-thumb': {
          borderRadius: '10px',
          backgroundColor: '#555',
          boxShadow: 'inset 0 0 6px rgba(0,0,0,.3)',
        },
        scrollbarWidth: 'thin',
        scrollbarColor: '#555 #F5F5F5',
      },
    })
);

const CookieCategory: React.FC<CookieCategoryProps> = ({ 
    title, 
    description, 
    checked, 
    onChange, 
    disabled 
  }) => {
    const [expanded, setExpanded] = useState(false);
  
    const handleExpandClick = () => {
      setExpanded(!expanded);
    };
  
    return (
      <Card elevation={0} style={{ 
        padding: 16, 
        margin: '8px 14px',
        backgroundColor: 'rgb(230 226 226)', 
        borderRadius: 6 
      }}>
        <CardContent style={{ padding: '8px 16px' }}>
          <Grid container alignItems='center' justifyContent='space-between' wrap="nowrap">
            <Grid container sm={11} lg={11} alignItems='center'>
              <Grid item>
                <IconButton
                  onClick={handleExpandClick}
                  style={{ 
                    padding: 4,
                    marginRight: 8,
                    transform: expanded ? 'rotate(180deg)' : 'rotate(0deg)',
                    transition: 'transform 0.3s ease-in-out',
                  }}
                >
                  {<ExpandMore />}
                </IconButton>
              </Grid>
              <Grid item style={{ flex: 1 }}>
                <Typography variant="subtitle1" style={{ color: "#2e2e2d", textWrap: "pretty",  whiteSpace: 'normal' }}>
                {title}
                </Typography>
              </Grid>
            </Grid>
            <Grid item sm={1} lg={1}>
              <FormControlLabel
                control={
                  <Switch
                    checked={checked}
                    onChange={onChange}
                    color="primary"
                    disabled={disabled}
                  />
                }
                label=""
              />
            </Grid>
          </Grid>
  
          <Collapse in={expanded} timeout="auto" unmountOnExit>
            <Typography 
              variant="body2" 
              style={{ 
                color: "#262625", 
                textAlign: "justify",
                textWrap: "pretty",
                marginTop: 8,
                paddingLeft: 40
              }}
            >
              {description}
            </Typography>
          </Collapse>
        </CardContent>
      </Card>
    );
};

export default function CookieConsent() {
    const classes = useStyles();
    const theme = useTheme();
    const history = useHistory();
    const [snackBarOpen, setSnackBarOpen] = useState<boolean>(false);
    const [activeCard, setActiveCard] = useState<"consent"| "details">("consent");
    const [cookiesConsent, setCookiesConsent] = useState<CookiesConsentState>({
        necessary: true,
        marketing: false
    });
    const [hasUserInteracted, setHasUserInteracted] = useState(false);
    const [cookies, setCookie] = useCookies(['necessary', 'marketing']);
    const handleSwitchChange = (name: keyof CookiesConsentState) => 
        (event: React.ChangeEvent<HTMLInputElement>) => {
        setCookiesConsent({ ...cookiesConsent, [name]: event.target.checked });
    };

    const handleAcceptAll = () => {
      setCookiesConsent({
        necessary: true,
        marketing: true 
      });
      setHasUserInteracted(true);
    };

    const handleDeclineAll = () => {
      setCookiesConsent({
        necessary: true,
        marketing: false 
      });
      setHasUserInteracted(true);
    }; 

    const handleSaveSettings = useCallback(() => {
      const cookieOptions = {
        path: '/',
        maxAge: 15768000, // 6 months in seconds
      };
      
      Object.entries(cookiesConsent).forEach(([key, value]) => {
        const cookieKey = key as keyof CookiesConsentState;
        setCookie(cookieKey, value, cookieOptions);
      });
      
      setSnackBarOpen(false);
    }, [cookiesConsent, setCookie]); // Include all dependencies


    // Save whenever consent changes
    useEffect(() => {
      if (hasUserInteracted) {
        handleSaveSettings();
      }
    }, [cookiesConsent, handleSaveSettings, hasUserInteracted]);

    useEffect(() => {
      if (cookies.necessary) {
          setSnackBarOpen(false);
      }
      else {
        setSnackBarOpen(true);
      }
    }, [cookies.necessary]);

    return (
        <Snackbar
            open={snackBarOpen}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            TransitionComponent={Slide}
            className={classes.snackbarRoot}
        >
            <Paper style={{ border: 20, backgroundColor: theme.palette.secondary.main, }}>
                {activeCard === 'consent' ? (
                <Grid container className={classes.container}>
                    <Grid item>
                        <Typography variant="h6" style={{ color: "white" }}>
                            Sušenky Cookies 🍪
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography 
                            variant="body1" 
                            style={{ 
                            marginBottom: theme.spacing(2),
                            marginTop: theme.spacing(2),
                            textAlign: "justify" ,
                            color: theme.palette.secondary.contrastText
                            }}
                        >
                            {Environment.fullName} používá soubory cookies k zajištění funkčnosti webu.
                            Kliknutím na „Přijmout vše“ souhlasíte s použitím všech cookies. Nastavení cookies můžete vždy
                            změnit v přehledu
                            <Link
                                onClick={() => history.push("/PrivacySettings")}
                                underline="always"
                                style={{ 
                                    marginLeft: theme.spacing(1),
                                    fontWeight: 500,
                                    color: theme.palette.secondary.contrastText,
                                    cursor: "pointer"
                                }}
                            >
                            Nastavení soukromí
                            </Link>
                            .
                        </Typography>
                    </Grid>
                    <Grid container>
                        <Grid container alignItems='center' xs={12} md={4} style={{ 
                          margin: theme.spacing(1, 0), 
                          paddingInlineStart: 10,
                          paddingInlineEnd: 10 }}>
                          <Button
                              fullWidth
                              variant="contained"
                              color="primary"
                              onClick={() => {
                                  handleDeclineAll();
                                  setSnackBarOpen(false);
                              }}
                              style={{
                                  borderRadius: 20,
                                  padding: theme.spacing(0, 3),
                                  textTransform: 'none'
                              }}
                          >
                          Odmítnout vše
                          </Button>
                        </Grid>
                        <Grid container alignItems='center' xs={12} md={4} style={{ 
                          margin: theme.spacing(1, 0), 
                          paddingInlineStart: 10,
                          paddingInlineEnd: 10 }}>
                          <Button
                              fullWidth
                              variant="contained"
                              color="primary"
                              onClick={() => setActiveCard('details')}
                              style={{
                                  borderRadius: 20,
                                  padding: theme.spacing(0, 3),
                                  textTransform: 'none',
                              }}
                          >
                          Podrobnosti
                          </Button>
                        </Grid>
                        <Grid container alignItems='center' xs={12} md={4} style={{ 
                          margin: theme.spacing(1, 0), 
                          paddingInlineStart: 10,
                          paddingInlineEnd: 10 }}>
                          <Button
                              fullWidth
                              variant="contained"
                              color="primary"
                              onClick={() => {
                                  handleAcceptAll();
                                  setSnackBarOpen(false);
                              }}
                              style={{
                                  borderRadius: 20,
                                  padding: theme.spacing(0, 3),
                                  textTransform: 'none',
                              }}
                          >
                          Příjmout vše
                          </Button>
                        </Grid>
                    </Grid>
                </Grid>   
                ) : (
                <Grid container>
                    {/* Header */}
                    <Grid container direction='row' alignItems='center' className={classes.stickyHeader}>
                        <Grid item>
                            <Typography variant="h6" style={{ marginLeft: theme.spacing(1), color: "white" }}>
                                Nastavení cookies
                            </Typography>
                        </Grid>
                    </Grid>

                    {/* Scrollable Content */}
                    <Grid item className={classes.scrollableContent}>
                        <CookieCategory
                            title="Cookies nezbytné pro fungování webu"
                            description="Některé soubory cookies jsou vyžadovány, aby byla zajištěna základní funkčnost. V našem případě slouží k umožnění přihlášení
                            k Vašemu registrovanému účtu. Ve výchozím nastavení jsou povoleny a nelze je zakázat."
                            checked={cookiesConsent.necessary}
                            disabled
                        />

                        <CookieCategory
                            title="Marketingové cookies"
                            description="Spolupracujeme s externími partnery Heureka.cz a Zbozi.cz. K měření výkonu reklamy na Zbozi.cz a Heureka.cz používáme soubory cookie,
                            které napomáhají doměřování konverzí. V případě udělení souhlasu jsou dodatečně zasílaný Vaše údaje (email, adresa, telefon) v zašifrované podobě."
                            checked={cookiesConsent.marketing}
                            onChange={handleSwitchChange("marketing")}
                        />
                    </Grid>

                    {/* Footer */}
                    <Grid container className={classes.stickyFooter}>
                        <Grid container alignItems='center' xs={12} md={4} style={{ 
                          margin: theme.spacing(1, 0), 
                          paddingInlineStart: 10,
                          paddingInlineEnd: 10 }}>
                          <Button
                              fullWidth
                              variant="contained"
                              color="primary"
                              onClick={() => {
                                  handleDeclineAll();
                                  setSnackBarOpen(false);
                              }}
                              style={{
                                  borderRadius: 20,
                                  padding: theme.spacing(0, 3),
                                  textTransform: 'none'
                              }}
                          >
                          Odmítnout vše
                          </Button>
                        </Grid>
                        <Grid container alignItems='center' xs={12} md={4} style={{ 
                          margin: theme.spacing(1, 0), 
                          paddingInlineStart: 10,
                          paddingInlineEnd: 10 }}>
                        <Button
                            fullWidth
                            variant="contained"
                            color="primary"
                            onClick={() => {
                                handleAcceptAll();
                                setSnackBarOpen(false);
                            }}
                            style={{
                                borderRadius: 20,
                                padding: theme.spacing(0, 3),
                                textTransform: 'none',
                            }}
                        >
                        Příjmout vše
                        </Button>
                        </Grid>
                        <Grid container alignItems='center' xs={12} md={4} style={{ 
                          margin: theme.spacing(1, 0), 
                          paddingInlineStart: 10,
                          paddingInlineEnd: 10 }}>
                          <Button
                              fullWidth
                              variant="contained"
                              color="primary"
                              onClick={handleSaveSettings}
                              style={{
                                  borderRadius: 20,
                                  padding: theme.spacing(0, 3),
                                  textTransform: 'none',
                              }}
                          >
                          Uložit nastavení
                          </Button>
                        </Grid>
                    </Grid>
                </Grid>
                )}
            </Paper>
        </Snackbar>
    )
};